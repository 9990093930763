import React from 'react';
import "./Carteirinha.css";
import avatar from "../images/fepap_logo.png";
import { getTokenName, getTokenStatus, getTokenContact, getTokenEmail, getTokenBegin, getTokenValidate } from "./Token";

export default function CarteirinhaDados() {    
    return (
        <div className="card-container">
			<header className="header-carteirinha">
				<img src={avatar}  />
			</header>
            <div className="conteudo">
                <h1 className="nome">
                    {getTokenName()}
                </h1>		
                <hr></hr>	
                <p>Status: <b>{getTokenStatus()}</b></p>
                <p>Data de afiliação: {getTokenBegin()}</p>
                <p>{getTokenEmail()} </p>
                <p>{getTokenContact()} </p>
                <div className='div-vencimento'>
                    <p className='paragrafo-vencimento'><b>Vencimento:</b> {getTokenValidate()}</p>
                </div>                                
            </div>
			<div>
                <div className="bottom-carteirinha">
                    <img src={avatar}  />
                </div>
                <p className='paragrafo-federacao'>Federação Paranaense de Pesca Esportiva - FEPAP</p>
            </div>
            <div className='rodape'></div>
		</div>
        
    );

}