import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EventoItem from './EventosItem'
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

export default function EventosList() {
    const [events, setEvents] = useState({});
    const getApi = () =>
    axios.create({
        baseURL: process.env.REACT_APP_ENDPOINT,
        auth: {
        username: process.env.REACT_APP_AUTH_USERNAME || "",
        password: process.env.REACT_APP_AUTH_PASSWORD || "",
        },
    });
    
    useEffect(() => {
        getApi().get("/eventos.php")
            .then((response) => (setEvents(response.data)))        
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
        });
    }, []);     
    console.log(events) 

    if (events == {})
    return (
        <Box display="flex" flexDirection="column" flex={1} alignItems="center" justifyContent="center" mt={2}>
            <CircularProgress color="primary" size={64} />
        </Box>
    );

    return Object.values(events).map(evento=><EventoItem {...evento} />);

}