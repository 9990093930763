import React from 'react';
import { Redirect } from 'react-router-dom';
import CarteirinhaDados from './CarteirinhaDados';
import { getToken, getTokenTime, logout } from "./Token";
export default function Carteirinha() {
    const tempoatual = Date.now();
    const tempoToken = getTokenTime();
    const tempoativo = (tempoatual - tempoToken)/1000/60
    if(getToken() == null){                
        return <Redirect to="/login" />
    }else if(tempoativo > 10080){             
        logout();
        return <Redirect to="/login" />
    }  
    return (
        <><CarteirinhaDados></CarteirinhaDados></>
    )
    
}
