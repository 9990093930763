import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const theme = createTheme();

export default function SignUp() {
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);    
        // eslint-disable-next-line no-console    
        
        const getApi = () =>
        axios.create({
            baseURL: process.env.REACT_APP_ENDPOINT,
            auth: {
            username: process.env.REACT_APP_AUTH_USERNAME || "",
            password: process.env.REACT_APP_AUTH_PASSWORD || "",
            },
        });
        
        getApi().post("/cadastro.php",{
            params:{
                cpf:data.get('cpf'),
                nome:data.get('nome'),
                contato:data.get('contato'),
                email:data.get('email'),
                password: data.get('password')
            }
        })
        .then((response) => {
            if(response.data == 0){            
                notify();
            }else{                        
                notifysuccess();
                setTimeout(function() {
                    window.location.href = "/noticias";
                }, 5000);
            }
        })
        .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });
    };
    const notify = () => {
        toast.error('Erro ao efetuar o cadastro, tente novamente.')  
    }
    const notifysuccess = () => {
        toast.success('Cadastro efetuado com sucesso, agora só aguardar a validação pela federação.')
    }
return (
    <><ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Novo cadastro
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="cpf"
                                label="CPF"
                                name="cpf"
                                autoComplete="cpf" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="nome"
                                label="Nome Completo"
                                name="nome"
                                autoComplete="nome" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="contato"
                                label="Contato"
                                name="contato"
                                autoComplete="contato" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Senha"
                                type="password"
                                id="password"
                                autoComplete="senha" />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Cadastrar
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/login" variant="body2">
                                Já tem uma conta? Acesse
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    </ThemeProvider><ToastContainer /></>
);
}
