import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles({
    cardEvents: {
        margin: '10px',
    },
});

export default function EventosItem({id, titulo, descricao}) {
    const classes = useStyles();
    return (
        <Link to={`/Evento/${id}`} style={{ textDecoration: 'none' }} >
            <Card className={classes.cardEvents}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="140"
                        image="https://flic.kr/p/2mTFsbL"
                        alt="Imagem evento"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        {titulo}
                        </Typography>                    
                        {descricao}                                       
                    </CardContent>
                </CardActionArea>
            </Card>
        </Link>
    );

}