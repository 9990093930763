import { Divider, Typography } from '@mui/material';
import React from 'react';
import EventosList from './EventosList';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    cardEvents: {
        margin: '10px',
    },
});

export default function Eventos() {
    const classes = useStyles();
    return (  
        <><Typography gutterBottom variant="h4" component="div" className={classes.cardEvents}>
            Eventos
        </Typography><Divider variant="middle" /><EventosList /></>
    );
}