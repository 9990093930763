import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import EventoDados from './EventoDados';

export default function Evento() {
    const { id } = useParams();
    const [event, setEvent] = useState({});
    const getApi = () =>
    axios.create({
        baseURL: process.env.REACT_APP_ENDPOINT,
        auth: {
        username: process.env.REACT_APP_AUTH_USERNAME || "",
        password: process.env.REACT_APP_AUTH_PASSWORD || "",
        },
    });
    
    useEffect(() => {
        getApi().post("/evento.php",{
            params:{
                evento:id
            }
        })
            .then((response) => (setEvent(response.data)))        
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
        });
    }, []);
    return (
        <>{Object.values(event).map(evento=><EventoDados {...evento} />)}</>
    )
}