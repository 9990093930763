export const TOKEN_KEY = "token";
export const TOKEN_NAME = "name";
export const TOKEN_STATUS = "status";
export const TOKEN_CONTACT = "contact";
export const TOKEN_EMAIL = "email";
export const TOKEN_BEGIN = "begin";
export const TOKEN_VALIDATE = "validate";
export const TOKEN_TIME = "time";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getTokenName = () => localStorage.getItem(TOKEN_NAME);
export const getTokenStatus = () => localStorage.getItem(TOKEN_STATUS);
export const getTokenContact = () => localStorage.getItem(TOKEN_CONTACT);
export const getTokenEmail = () => localStorage.getItem(TOKEN_EMAIL);
export const getTokenBegin = () => localStorage.getItem(TOKEN_BEGIN);
export const getTokenValidate = () => localStorage.getItem(TOKEN_VALIDATE);
export const getTokenTime = () => localStorage.getItem(TOKEN_TIME);

export const settokenlogin = (token, name, status, contact, email, validate, begin) => {  
  const tempoatual = Date.now();
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(TOKEN_NAME, name);
  localStorage.setItem(TOKEN_STATUS, status);
  localStorage.setItem(TOKEN_CONTACT, contact);
  localStorage.setItem(TOKEN_EMAIL, email);
  localStorage.setItem(TOKEN_BEGIN, begin);
  localStorage.setItem(TOKEN_VALIDATE, validate);
  localStorage.setItem(TOKEN_TIME, tempoatual);  
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_NAME);
  localStorage.removeItem(TOKEN_STATUS);
  localStorage.removeItem(TOKEN_CONTACT);
  localStorage.removeItem(TOKEN_EMAIL);
  localStorage.removeItem(TOKEN_BEGIN);
  localStorage.removeItem(TOKEN_VALIDATE);
  localStorage.removeItem(TOKEN_TIME);
};
