import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles({
    cardEvents: {
        margin: '10px',
    },
    backButton:{
        margin: '10px'
    }
});

export default function EventoDados({id, titulo, texto}) {
    const classes = useStyles();
    return (
        <>
            <Button variant="contained" className={classes.backButton} component={Link} to="/Eventos">
                Voltar
              </Button>
            <div className={classes.cardEvents} dangerouslySetInnerHTML={{__html: texto}}></div>
        </>
        
    );

}