import * as React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Noticias from "./pages/Noticias";
import Carteirinha from "./pages/Carteirinha";
import Eventos from "./pages/Eventos";
import Login from "./pages/Login";
import Cadastro from "./pages/Cadastro";
import Noticia from "./pages/Noticia";
import Evento from "./pages/Evento";

function App(): React.ReactElement {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <main>
      <Router>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
              FEPAP
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <Link to="/">Notícias</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to="/eventos">Eventos</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to="/carteirinha">Carteirinha</Link>
                </MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
        </Box>
        <Switch>
          <Route path="/eventos">
            <Eventos />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/cadastro">
            <Cadastro />
          </Route>
          <Route path="/carteirinha">
            <Carteirinha />
          </Route>
          <Route path="/noticia/:id">
            <Noticia />
          </Route>
          <Route path="/evento/:id">
            <Evento />
          </Route>
          <Route path="/">
            <Noticias />
          </Route>
        </Switch>
      </Router>
    </main>
  );
}

export default App;
