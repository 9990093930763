import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { settokenlogin } from "./Token";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';

const theme = createTheme();

export default function Login() {
  const [logado, setLogado] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);    
    // eslint-disable-next-line no-console    
    
    const getApi = () =>
    axios.create({
        baseURL: process.env.REACT_APP_ENDPOINT,
        auth: {
        username: process.env.REACT_APP_AUTH_USERNAME || "",
        password: process.env.REACT_APP_AUTH_PASSWORD || "",
        },
    });
    
    getApi().post("/login.php",{
        params:{
            email:data.get('email'),
            password: data.get('password')
        }
    })
    .then((response) => {
      if(response.data == 0){        
        notify();
      }else{        
        settokenlogin(response.data['hash'], response.data['nome'], response.data['status'], response.data['contato'], response.data['email'], response.data['vencimento'], response.data['filiacao']);        
        setLogado(true);
        
      }
    })
    .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
    });
  };

  const notify = () => {
    toast.error('Dados inválidos')  
  }
  if(logado){
    return <Redirect to="/carteirinha" />
  }
  return (
    <><ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password" />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Acessar carteirinha
            </Button>
            <Grid container>
              <Grid item xs>
              </Grid>
              <Grid item>              
                <Link href="/cadastro" variant="body2">
                  {"Ainda não tem acesso? Cadastre-se"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider><ToastContainer /></>
  );
}
